<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-10>
      <v-flex xs12 sm10>
        <v-card>
          <v-layout wrap justify-center py-5>
            <v-flex xs11 sm10>
              <v-layout wrap>
                <v-flex xs12 text-center>
                  <span class="itemHeading">TRAVEL REQUEST</span>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center pt-5>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">Source</span>
                  <v-text-field
                    outlined
                    dense
                    v-model="travelData.source"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">Destinaton</span>
                  <v-text-field
                    outlined
                    dense
                    v-model="travelData.destination"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 text-left>
                  <span class="itemText2">Mode of Travel</span>
                  <v-select
                    v-model="travelData.travelMode"
                    :items="travelModes"
                    dense
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 text-left>
                  <span class="itemText2">Description</span>
                  <v-textarea
                    v-model="travelData.description"
                    outlined
                  ></v-textarea>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">Start Date</span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormattedFrom"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                      :min="minDateStart"
                      @change="checkDateTime()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">Start Time</span>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startTime"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="startTime"
                      full-width
                      @change="checkDateTime()"
                      @click:minute="$refs.menu2.save(startTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">End Date</span>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormattedTo"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      :min="minDate"
                      @change="checkDateTime()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu3 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu3.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  text-left
                  pr-0
                  pr-xs-0
                  pr-sm-2
                  pr-md-2
                  pr-lg-2
                  pr-xl-2
                >
                  <span class="itemText2">End Time</span>
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="endTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endTime"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu4"
                      v-model="endTime"
                      full-width
                      @change="checkDateTime()"
                      @click:minute="$refs.menu4.save(endTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 py-5>
                  <v-btn color="#005f32" dark @click="validateInput()"
                    ><span style="font-family: kumbhRegular"
                      >Submit</span
                    ></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      visible: false,
      showsnackbar: false,
      multi: true,
      orgId: localStorage.getItem("orgid"),
      userId: localStorage.getItem("userid"),
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      startDate:new Date().toISOString().split("T")[0],
      endDate:new Date().toISOString().split("T")[0],
      startTime: "",
      endTime: "",
      appliedBy: "me",
      appliedFor: null,
      message: "",
      msg: "",
      travelData: {
        id: null,
        source: null,
        destination: null,
        description: null,
        travelMode: null,
      },
      travelModes: [],
      isDateCorrect: true,
      dates: [],
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      minDateStart: new Date().toISOString().split("T")[0], // Format date to YYYY-MM-DD
      minDate: new Date().toISOString().split("T")[0], // Format date to YYYY-MM-DD
    };
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDatePicker(this.startDate);
    },
    computedDateFormattedTo() {
      return this.formatDatePicker(this.endDate);
    },
  },
  // watch: {
  //   startDate(newStartDate) {
  //     if (newStartDate) {
  //       const newMinDate = new Date(newStartDate).toISOString().split("T")[0];
  //       this.minDate = newMinDate;

  //       // Clear endDate if it's before the new startDate
  //       if (this.endDate && new Date(this.endDate) < new Date(newStartDate)) {
  //         this.endDate = null;
  //       }
  //     }
  //   },
  // },
  mounted: function () {
    this.getData();
    this.getTravelMode();
  },
  methods: {
    checkDateTime() {
      if (this.startDate && this.endDate && this.startTime && this.endTime) {
        var startDateTime = new Date(this.startDate);
        var endDateTime = new Date(this.endDate);
        var sTime = this.startTime.split(":");
        var eTime = this.endTime.split(":");
        startDateTime.setHours(sTime[0], sTime[1], 0, 0);
        endDateTime.setHours(eTime[0], eTime[1], 0, 0);

        if (startDateTime.getTime() >= endDateTime.getTime()) {
          this.msg =
            "Please ensure the end date and time is after the start date and time";
          this.showsnackbar = true;
        }
      }
    },
    validateInput() {
      if (!this.travelData.source) {
        this.msg = "Please provide source";
        this.showsnackbar = true;
        return;
      } else if (!this.travelData.destination) {
        this.msg = "Please provide destination";
        this.showsnackbar = true;
        return;
      } else if (!this.travelData.travelMode) {
        this.msg = "Please select travel mode";
        this.showsnackbar = true;
        return;
      } else if (!this.startDate) {
        this.msg = "Please select start Date";
        this.showsnackbar = true;
        return;
      } else if (!this.endDate) {
        this.msg = "Please select end date";
        this.showsnackbar = true;
        return;
      } else if (!this.startTime) {
        this.msg = "Please select start time";
        this.showsnackbar = true;
        return;
      } else if (!this.endTime) {
        this.msg = "Please select end time";
        this.showsnackbar = true;
        return;
      } else if (
        this.startDate && this.endDate && this.startTime && this.endTime
      ) {
        var startDateTime = new Date(this.startDate);
        var endDateTime = new Date(this.endDate);
        var sTime = this.startTime.split(":");
        var eTime = this.endTime.split(":");
        startDateTime.setHours(sTime[0], sTime[1], 0, 0);
        endDateTime.setHours(eTime[0], eTime[1], 0, 0);

        if (startDateTime.getTime() >= endDateTime.getTime()) {
          this.msg =
            "Please ensure the end date and time is after the start date and time.";
          this.showsnackbar = true;
        }
        else {
        this.applyLeave();
      }
      } else {
        this.applyLeave();
      }
    },
    formatDate(date) {
      return date.toLocaleDateString();
    },
    getTravelMode() {
      axios({
        method: "GET",
        url: "/travelMode/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status) {
            this.travelModes = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    getData() {
      this.visible = true;
      axios({
        method: "GET",
        url: "/travelLog/view/" + this.$route.query.id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.visible = false;
            this.travelData = response.data.data;
            this.startDate = this.travelData.startingTime.slice(0, 10);
            this.endDate = this.travelData.endingTime.slice(0, 10);
            this.startTime = this.convertUTCToLocal(
              this.travelData.startingTime
            );
            this.endTime = this.convertUTCToLocal(this.travelData.endingTime);
            this.status = response.data.data.status;
          } else if (response.data.status === false) {
            alert(response.data.msg);
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    applyLeave() {
      var url;
      if (this.$route.query.type == "edit") {
        url = "/travelLog/edit";
        this.travelData.id = this.$route.query.id;
        this.travelData.startingTime = `${this.startDate} ${this.startTime}`;
        this.travelData.endingTime = `${this.endDate} ${this.endTime}`;
      } else {
        this.travelData.startingTime = `${this.startDate} ${this.startTime}`;
        this.travelData.endingTime = `${this.endDate} ${this.endTime}`;
        url = "/travelLog/add";
      }

      axios({
        method: "POST",
        url: url,
        data: this.travelData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push({ name: "myTravelogue" });
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    convertUTCToLocal(utcDateString) {
      // Create a new Date object from the UTC date string
      var utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const adjustedHours = hours % 12 || 12;
      const formattedTime = `${adjustedHours}:${minutes}`;
      return formattedTime;
    },
    formatDatePicker(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>
  <style >
.selected-date {
  /* cursor: pointer; */
  min-height: 30x !important;
  max-width: 100% !important;
}
</style>
  